import { Table } from "@tanstack/react-table";
import { SequenceTemplate } from "@/types/sequence";
import { useAppSelector } from "@/redux/store";
import TsTable from "@/common/components/ts-table";
import SequenceVersions from "../versions";
import { useMemo, useState } from "react";
import { SquaresPlusIcon } from "@heroicons/react/24/outline";
import { SequenceFiltersInput } from "..";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";

type SequenceTableProps = {
  filters: SequenceFiltersInput | undefined;
  table: Table<SequenceTemplate>;
};

export default function SequenceTable({ table, filters }: SequenceTableProps) {
  const sequences = useAppSelector((selector) => selector.sequences.listSequences.sequences);
  const sequencesLoading = useAppSelector((selector) => selector.sequences.listSequences.loading);
  const [currentSequenceId, setCurrentSequenceId] = useState<string>();

  const currentSequence = useMemo(() => {
    return (sequences || []).find((sequence) => sequence.id === currentSequenceId);
  }, [currentSequenceId, sequences]);

  const handleCellClick = async (id: string) => {
    setCurrentSequenceId(id);
  };

  // TODO: Temporary solution to show no sequences found message when filters are applied.
  const hasNameFilter = filters?.name !== undefined && filters?.name !== "";
  const hasSelectedStatusFilter =
    filters?.status !== undefined && filters?.status.length > 0 && filters?.status.length !== 3;
  const hasFilters = hasNameFilter || hasSelectedStatusFilter;

  return (
    <>
      <FullLoadingOverlay loading={sequencesLoading} />
      {sequences && (
        <TsTable
          table={table}
          handleCellClick={handleCellClick}
          notFoundProps={{
            title:
              sequences.length === 0 && hasFilters
                ? "No sequences found that match your criteria"
                : "No sequences found",
            description:
              sequences.length === 0 && hasFilters ? (
                <span>Try a different search parameter or simply clear all the filters with the button below.</span>
              ) : (
                <span>
                  Begin creating an agent by clicking the <b>"Create Sequence"</b> button above.
                </span>
              ),
            Icon: SquaresPlusIcon,
          }}
        />
      )}
      {currentSequence && (
        <SequenceVersions
          open={!!currentSequence}
          sequence={currentSequence}
          versions={currentSequence.versions ?? []}
          onClose={() => setCurrentSequenceId(undefined)}
        />
      )}
    </>
  );
}
