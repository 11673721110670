import { GridDeleteIcon } from "@mui/x-data-grid";
import { Typography, Box, IconButton } from "@mui/material";
import AddUser from "../create";
import React, { useState, useMemo, useEffect } from "react";
import Dropdown from "@/common/components/dropdown/Dropdown";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { createColumnHelper } from "@tanstack/react-table";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { usePagination } from "@/common/components/pagination/usePagination";
import TsTable from "@/common/components/ts-table";
import SearchBar from "@/common/components/searchbar/Searchbar";
import useConfirmModal from "@/common/hooks/useConfirmModal";
import { deleteUserFromTeam, updateTeamUser } from "@/redux/reducers/settings/team";
import { KnTeamUserRole, KnTeamUserRoleUserRoleEnum, UpdateTeamUserRequest } from "@/services/generated";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import { useAuth0 } from "@auth0/auth0-react";
import { Roles } from "@/common/constants/roles";
import TableHeader from "@/common/components/table-header";
import TableCell from "@/common/components/table-cell";
import createCheckboxCol from "@/common/functions/createCheckboxCol";

const columnHelper = createColumnHelper<TeamUser>();

interface TeamUser extends KnTeamUserRole {
  id: string;
  isDeletable: boolean;
}

export default function UserManagementTable() {
  const { user } = useAuth0();
  const role = user?.team_user_role;
  const users = useAppSelector(({ settings }) => settings.team.users);
  const invites = useAppSelector(({ settings }) => settings.team.invites);
  const loadingUpdateUser = useAppSelector(({ settings }) => settings.team.loadingUpdateUser);
  const { onPaginationChange, pagination, skip, limit } = usePagination();
  const { deleteConfirm } = useConfirmModal();
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [pageData, setPageData] = useState<TeamUser[]>([]);

  const dispatch = useAppDispatch();

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event?.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  const handleRoleChange = (user: TeamUser, option: { label: string; value: KnTeamUserRoleUserRoleEnum }) => {
    const updateRequest: UpdateTeamUserRequest = {
      userId: user.user_id || "",
      teamId: user.team_id || "",
      userRole: option.value,
    };

    dispatch(updateTeamUser(updateRequest));
  };

  const handleDelete = async (id: string | undefined) => {
    try {
      await deleteConfirm({
        textDiv: (
          <>
            <Typography variant="body1">Are you sure you want to delete this user from this team?</Typography>
            <Typography variant="body1">This action cannot be undone.</Typography>
          </>
        ),
      });
      dispatch(deleteUserFromTeam(id || ""));
    } catch {}
  };

  const columns = useMemo(
    () => [
      createCheckboxCol(columnHelper),

      columnHelper.accessor("firstName", {
        header: () => <TableHeader>User Full Name</TableHeader>,
        cell: (info) => {
          const row = info.row;
          return <TableCell>{`${info.getValue()} ${row.original.lastName}`}</TableCell>;
        },
      }),

      columnHelper.accessor("calendarConnected", {
        header: () => <TableHeader>Calendar Connected</TableHeader>,
        cell: (info) => <TableCell>{info.getValue() ? "Yes" : "No"}</TableCell>,
      }),

      columnHelper.accessor("email", {
        header: () => <TableHeader>Email</TableHeader>,
        cell: (info) => <TableCell>{info.getValue()}</TableCell>,
      }),

      columnHelper.accessor("userRole", {
        header: () => <TableHeader>Role</TableHeader>,
        cell: (info) => (
          <Dropdown
            label="Role"
            placeholder="Role"
            isDisabled={role !== KnTeamUserRoleUserRoleEnum.Manager || info.row.original.userRole === undefined}
            options={Roles?.map((role) => ({
              label: role.roleName,
              value: role.roleCode,
            }))}
            value={info.getValue()}
            onChange={(option) => handleRoleChange(info.row.original, option)}
          />
        ),
      }),

      columnHelper.accessor("isDeletable", {
        cell: (info) => {
          const isDeletable = info.row.original.isDeletable;

          if (role === KnTeamUserRoleUserRoleEnum.Representative) {
            return null;
          }

          return (
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <IconButton
                aria-label="delete"
                onClick={() => handleDelete(info.row.original.user_id)}
                disabled={!isDeletable}
              >
                <GridDeleteIcon color={isDeletable ? "error" : "disabled"} />
              </IconButton>
            </Box>
          );
        },
      }),
    ],
    [],
  );

  const data: TeamUser[] = useMemo(() => {
    const all = [
      ...users.map((teamUserRole) => {
        return {
          ...teamUserRole,
          id: teamUserRole.id || "",
          isDeletable: true,
        };
      }),
      ...invites
        .filter((invite) => invite.status === "SENT")
        .map((invite) => {
          return {
            id: invite.id || "",
            user_id: invite.id,
            team_id: user?.team_id,
            firstName: invite.full_name,
            lastName: "",
            calendarConnected: false,
            email: invite.email,
            userRole: invite.role,
            isDeletable: false,
          };
        }),
    ];
    if (filter) {
      return all.filter(
        (user) =>
          user.firstName?.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
          user.lastName?.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
          user.email?.includes(filter),
      );
    }
    return all;
  }, [users, invites, filter, user?.team_id]);

  useEffect(() => {
    setPageData(data.slice(skip, skip + limit));
  }, [data, limit, skip]);

  const table = useReactTable<TeamUser>({
    data: pageData,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    pageCount: data.length,
    manualPagination: true,
    state: {
      pagination: {
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
      },
    },
    onPaginationChange,
  });

  return (
    <Box sx={{ display: "flex", flex: 1, gap: "16px", flexDirection: "column", position: "relative" }}>
      {loadingUpdateUser && <LoadingSpinner />}
      {!loadingUpdateUser && (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ width: "50%" }}>
              <SearchBar
                sx={{ flexGrow: 1 }}
                placeholder="Search"
                value={filter}
                onChange={(event) => {
                  setFilter(event.target.value);
                }}
              />
            </Box>
            <Box>
              {role === KnTeamUserRoleUserRoleEnum.Manager && <AddUser toggleDrawer={toggleDrawer} isOpen={isOpen} />}
            </Box>
          </Box>
          <TsTable table={table} handleCellClick={() => null} />
        </>
      )}
    </Box>
  );
}
