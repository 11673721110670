import Dropdown from "@/common/components/dropdown/Dropdown";
import Searchbar from "@/common/components/searchbar/Searchbar";
import { SequenceTemplateStatusType } from "@/types/sequence";
import Box from "@mui/material/Box";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { SequenceFiltersInput } from "..";
import { DEFAULT_STATUS_FILTER } from "@/redux/reducers/sequences/slices/listSequences";

const SEQUENCE_STATUS_SELECT_OPTIONS = [
  { label: "All", value: "ALL" },
  { label: "Active", value: "ACTIVE" },
  { label: "Draft", value: "DRAFT" },
  { label: "Inactive", value: "INACTIVE" },
];

export type SequenceFiltersProps = {
  filters: SequenceFiltersInput | undefined;
  setFilters: (filters: SequenceFiltersInput | ((prevFilters: SequenceFiltersInput) => SequenceFiltersInput)) => void;
};

export default function SequenceFilters({ filters, setFilters }: SequenceFiltersProps) {
  const [nameFilter, setNameFilter] = useState<string | null>(null);

  const debouncedSetFilters = useMemo(
    () =>
      debounce((value) => {
        setFilters((prev) => ({ ...prev, name: value }));
      }, 500),
    [setFilters],
  );

  const handleChangeStatus = (option: { value: SequenceTemplateStatusType | "ALL" }) => {
    if (option.value === "ALL" || !option.value) {
      setFilters((previousFilters) => ({
        ...previousFilters,
        status: DEFAULT_STATUS_FILTER,
      }));

      return;
    }

    setFilters((previousFilters) => ({ ...previousFilters, status: [option.value as SequenceTemplateStatusType] }));
  };

  return (
    <form>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 2fr 1fr",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <Box sx={{ gridColumn: "1 / 2" }}>
          <Searchbar
            value={nameFilter || ""}
            onChange={(event) => {
              debouncedSetFilters(event.target.value);
              setNameFilter(event.target.value);
            }}
            placeholder="Search by Name"
          />
        </Box>

        <Box sx={{ gridColumn: "3 / 4" }}>
          <Dropdown
            label="Status"
            placeholder="Status"
            options={SEQUENCE_STATUS_SELECT_OPTIONS}
            value={!filters?.status || filters.status.length > 1 ? "ALL" : filters.status?.[0]}
            onChange={handleChangeStatus}
          />
        </Box>
      </Box>
    </form>
  );
}
